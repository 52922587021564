var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"width":"150","flat":"","color":"transparent"}},[_c('v-row',{attrs:{"justify":"end","no-gutters":""}},[_c('div',{staticClass:"my-1 mr-3"},[_c('v-row',{staticClass:"pa-1",attrs:{"no-gutters":"","justify":"end"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openPatientAction('note')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-document-box-plus-outline")])],1)]}}])},[_vm._v(" Add Note ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openPatientAction('message')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-message-text-outline")])],1)]}}])},[_vm._v(" Send Message ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openPatientAction('video')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-message-video")])],1)]}}])},[_vm._v(" Start Telehealth Session ")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
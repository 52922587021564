<template>
  <v-card width="150" flat color="transparent">
    <v-row justify="end" no-gutters>
      <div class="my-1 mr-3">
        <v-row class="pa-1" no-gutters justify="end">

          <v-tooltip
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-1"
                @click.stop.prevent="openPatientAction('note')"
                v-bind="attrs"
                v-on="on"
                icon
              >
         
                  <v-icon>mdi-file-document-box-plus-outline</v-icon>
         
              </v-btn>
            </template>
            Add Note
          </v-tooltip>

          <v-tooltip
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-1"
                @click.stop.prevent="openPatientAction('message')"
                v-bind="attrs"
                v-on="on"
                icon
              >
         
                  <v-icon>mdi-message-text-outline</v-icon>
         
              </v-btn>
            </template>
            Send Message
          </v-tooltip>

          <v-tooltip
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-1"
                @click.stop.prevent="openPatientAction('video')"
                v-bind="attrs"
                v-on="on"
                icon
              >
         
                  <v-icon>mdi-message-video</v-icon>
         
              </v-btn>
            </template>
            Start Telehealth Session
          </v-tooltip>

          

        </v-row>
      </div>
    </v-row>
  </v-card>
</template>

<script>
import moment from 'moment-timezone'
import HelperMixin from '@/core/mixins/HelperMixin'
import { mapActions } from 'vuex'

export default {
  mixins: [HelperMixin],
  data() {
    return {
      show: false,
      showExpansion: false,
      lastNote: null,
    }
  },
  props: {
    item: null,
    closeOnTime: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
  },
  methods: {
    ...mapActions('notes', ['getJustTheLastNote']),
    openPatientAction(action) {
      this.$router.push({
          name: 'PatientFeed',
          params: { id: this.item._id },
          query: {open: action}
        })
    },
    relativeTime2(value) {
      let now = new Date().getTime()
      var Difference_In_Time = now - value

      let days = Difference_In_Time / (3600000 * 24)

      // if tis less than three days ago use moment.
      if (value && Math.floor(days) < 90) {
        let dt = moment(value)
          .tz(moment.tz.guess())
          .format('MM/DD/YYYY h:mm a')
        if (dt.includes('12:00 am')) {
          return 'Today'
        } else {
          return moment(value)
            .tz(moment.tz.guess())
            .calendar()
        }
      } else if (value) {
        return this.getConversationalDateAndTimeFromTimestamp(value)
      }
      return ''
    },
  },
  mounted() {
    this.show = true
  },
}
</script>

<style></style>

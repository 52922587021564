<template>
  <v-col cols="12" class="px-4">
    <v-expand-transition>
      <v-card
        class="mt-3 mb-4"
        v-show="percentCompleted < 100"
        flat
        height="150"
        color="grey lighten-5"
      >
        <v-row align="center" justify="center" class="fill-height">
          <v-card v-show="percentCompleted < 100" color="transparent" flat>
            <v-row
              class="text-body-1 font-weight-medium mb-2 text--secondary"
              no-gutters
            >
              Analyzing Patient Population
            </v-row>

            <v-row no-gutters>
              <v-progress-linear
                :indeterminate="viableList.length === 0"
                :value="percentCompleted"
              />
            </v-row>
            <v-row justify="end" class="text-caption mt-1" no-gutters>
              <span>{{ viableList.length }}</span>
            </v-row>
          </v-card>
        </v-row>
      </v-card>
    </v-expand-transition>

    <economics-category-section-title permanentHighlight :icon="'mdi-at'" @toggleConditional="expandUserMentions = !expandUserMentions" :conditional="userMentions.length > 0" :active="expandUserMentions" :title="user.firstName + ' ' + user.lastName" :total="userMentions.length">
    </economics-category-section-title>

    <v-slide-y-transition class="py-0" group>
      <patient-summary-card-alert
        v-show="item && expandUserMentions"
        class="mb-2"
        v-for="(item, index) in userMentions"
        :key="index"
        :item="item.patient"
        :cardType="'noteMention'"
        :mentionText="item.note"
      />
    </v-slide-y-transition>


    <economics-category-section-title @toggleConditional="expandUnreadMessages = !expandUnreadMessages" :conditional="patientsWithUnreadMessages.length > 0" :active="expandUnreadMessages" :title="'New Patient Messages'" :total="patientsWithUnreadMessages.length"/>

    <v-slide-y-transition class="py-0" group>
      <patient-summary-card-alert
        v-show="item && expandUnreadMessages"
        class="mb-2"
        v-for="(item, index) in patientsWithUnreadMessages"
        :key="index"
        :item="item"
        :cardType="'Messages'"
      />
    </v-slide-y-transition>

  <economics-category-section-title @toggleConditional="expandUnacknowledgedAlerts = !expandUnacknowledgedAlerts" :conditional="patientsWithUnacknowledgedAlerts.length > 0" :active="expandUnacknowledgedAlerts" :title="'New Threshold Alerts'" :total="patientsWithUnacknowledgedAlerts.length"/>


    <v-card
      v-show="patientsWithUnacknowledgedAlerts.length > 0"
      flat
      class="pb-4"
    >
      <span class="text-body-2 text--secondary">
        Patients who's most recent measurement is above or below the threshold
        and the breach has not yet been noted by a staff member. To acknowledge
        the threshold breach, click the card below to add a note to the
        patient's chart.
      </span>
    </v-card>

    <v-slide-y-transition class="py-0" group>
      <patient-summary-card-alert
        :cardType="'alert'"
        v-show="item && expandUnacknowledgedAlerts"
        class="mb-2"
        v-for="(item, index) in patientsWithUnacknowledgedAlerts"
        :key="index"
        :item="item"
      />
    </v-slide-y-transition>

    <!--CRITICAL TODAY-->

  <economics-category-section-title
  @toggleConditional="expandHighPriority = !expandHighPriority"
  :conditional="true"
  :active="expandHighPriority"
  :title="'High Priority Patients'"
  :total="criticalToday.length"
  @massText="$emit('sendMassText', criticalToday)"
  :allowText="true"
  :progress="percentCompleted"
  />

    <v-card flat class="pb-4">
      <span class="text-body-2 text--secondary">
        High priority patients typically need to take a measurement today or
        tomorrow or they will no longer be eligible for RPM this period.
        Patients who have been inactive for more than 30 days are not included
        in the priority list. The objective with each high priority patient is
        to contact them and help them take a measurement today.
      </span>
    </v-card>

    <v-slide-y-transition class="py-0" group>
      <patient-summary-card-alert
        v-show="item && expandHighPriority"
        class="mb-2"
        v-for="(item, index) in criticalToday"
        :key="index"
        :item="item"
      />
    </v-slide-y-transition>
    <v-slide-y-transition>
      <v-card
        v-show="criticalToday.length === 0 && expandHighPriority"
        flat
        color="grey lighten-5"
        height="60"
      >
        <v-row
          v-if="criticalToday.length === 0 && percentCompleted >= 100"
          align="center"
          class="fill-height"
          justify="center"
        >
          <span class="text-caption">No High Priority Patients Identified</span>
        </v-row>
      </v-card>
    </v-slide-y-transition>

    <!-- really close-->


<economics-category-section-title
  @toggleConditional="expandCloseToQualifying = !expandCloseToQualifying"
  :conditional="true"
  :active="expandCloseToQualifying"
  :title="'Patients Close to Qualifying'"
  :total="reallyClose.length + closeOnTime.length"
  @massText="$emit('sendMassText', reallyClose)"
  :allowText="true"
  :progress="percentCompleted"
  />

    <v-card flat class="pb-4">
      <span class="text-body-2 text--secondary">
        Patients are considered close to qualifying when they are within a few
        days of meeting CMS RPM compliance requirements for days of data within
        their current RPM period or are within 3 minutes of qualifing for 20
        minutes of staff time for active monitoring.
      </span>
    </v-card>

    <v-slide-y-transition class="py-0" group>
      <patient-summary-card-alert
        v-show="item && expandCloseToQualifying"
        class="mb-2"
        v-for="(item, index) in reallyClose"
        :key="index"
        :item="item"
      />
    </v-slide-y-transition>

    <v-slide-y-transition class="py-0" group>
      <patient-summary-card-alert
        v-show="item && expandCloseToQualifying"
        class="mb-2"
        v-for="(item, index) in closeOnTime"
        :key="index"
        :item="item"
        :cardType="'closeOnTime'"
      />
    </v-slide-y-transition>

    <v-card
      v-if="closeOnTime.length === 0 && reallyClose.length === 0"
      flat
      color="grey lighten-5"
      height="60"
    >
      <v-row
        v-if="
          closeOnTime.length === 0 &&
          reallyClose.length === 0 &&
          percentCompleted >= 100
        "
        align="center"
        class="fill-height"
        justify="center"
      >
        <span class="text-caption">No Patients Identified</span>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
import bus from "@/core/helpers/bus";
import { mapActions, mapMutations, mapState } from 'vuex'
//import PatientSummaryCard from "../components/PatientSummaryCard.vue";
import moment from 'moment-timezone'
import PatientSummaryCardAlert from '../components/PatientSummaryCardAlert.vue'
import EconomicsCategorySectionTitle from '../components/EconomicsCategorySectionTitle.vue'

export default {
  components: {
    // PatientSummaryCard,
    PatientSummaryCardAlert,
    EconomicsCategorySectionTitle,
  },
  data() {
    return {
      expandUserMentions: true,
      expandUnacknowledgedAlerts: false,
      expandUnreadMessages: false,
      expandHighPriority: true,
      expandCloseToQualifying: true,
      hideRead: false,
      viableSorted: [],
      overallProgress: 0,
      mightNeedARefresh: false,
      displayedHighPriority: [],
    }
  },
  computed: {
    ...mapState('taskboard', ['enrolledPatients', 'unreadMessages']),
    ...mapState('metrics', ['viableList']),
    ...mapState('auth', ['userMentions', 'userAssignments', 'user']),
    percentCompleted() {
      if (this.viableList.length > 0) {
        return (this.viableList.length / this.enrolledPatients.length) * 100
      }
      return 0
    },
    actualViable() {
      if (this.viableList) {
        let sorted = this.viableList.filter(function (e) {
          return e.isViableThisPeriod && e.daysNeeded > 0
        })
        return sorted
      }
      return []
    },
    alreadyQualified() {
      if (this.viableList) {
        let sorted = this.viableList.filter(function (e) {
          return e.isViableThisPeriod && e.daysNeeded <= 0
        })
        return sorted
      }
      return []
    },
    criticalToday() {
      if (this.actualViable) {
        let sorted = this.actualViable.filter(function (e) {
          return e.rank > 0.9 && e.tookReadingRecently
        })
        return sorted
      }
      return []
    },
    reallyClose() {
      if (this.actualViable) {
        let sorted = this.actualViable.filter(function (e) {
          return e.daysNeeded < 5
        })
        return sorted
      }
      return []
    },
    closeOnTime() {
      if (this.actualViable) {
        let sorted = this.actualViable.filter(function (e) {
          return e.closeOnTime
        })
        return sorted
      }
      return []
    },
    doingGreat() {
      if (this.actualViable) {
        let sorted = this.actualViable.filter(function (e) {
          return e.isOnTrack
        })
        return sorted
      }
      return []
    },
    notGreat() {
      if (this.actualViable) {
        let sorted = this.actualViable.filter(function (e) {
          return !e.isOnTrack && e.rank <= 0.9 && e.daysNeeded < 16
        })
        return sorted
      }
      return []
    },
    willNotQualifyCurrentPeriod() {
      if (this.viableList) {
        let sorted = this.viableList.filter(function (e) {
          return !e.isViableThisPeriod
        })
        return sorted
      }
      return []
    },
    patientsWithUnreadMessages() {
      return this.enrolledPatients.filter(function (e) {
        return e.unreadMessage
      })
    },
    patientsWithUnacknowledgedAlerts() {
      return this.enrolledPatients.filter(function (e) {
        return e.rpmUnseenAlerts
      })
    },
  },
  methods: {
    ...mapActions('metrics', ['gradePatientOnEngagement']),
    ...mapActions('notes', ['getJustTheLastNote']),
    ...mapMutations('metrics', ['resetViability']),
    toggleHighPriority() {
      if (this.displayedHighPriority.length === 0) {
        // high prooroty is hidded go display it.
        this.criticalToday.forEach((patient) => {
          this.displayedHighPriority.push(patient)
        })
      } else {
        this.displayedHighPriority = []
      }
    },
    goToPatient(patient) {
      this.$router.push({
        name: 'PatientFeed',
        params: { id: patient._id },
      })
    },
    relativeTime(value) {
      if (value) {
        let dt = moment(value).tz(moment.tz.guess()).format('MM/DD/YYYY h:mm a')
        if (dt.includes('12:00 am')) {
          return 'Today'
        } else {
          return moment(value).tz(moment.tz.guess()).fromNow()
        }
      }
      return '-'
    },
    refreshTheStuff() {
      this.resetViability()
      this.mightNeedARefresh = false
      this.overallProgress = 0
      this.enrolledPatients.forEach((patient) => {
        this.gradePatientOnEngagement(patient)
      })
    },
  },
  watch: {
    enrolledPatients() {
      console.log()
      if (this.enrolledPatients.length > 0 && this.viableList.length === 0) {
        this.overallProgress = 0
        this.enrolledPatients.forEach((patient) => {
          this.gradePatientOnEngagement(patient)
        })
      } else {
        this.$emit('mightNeedARefresh')
        this.mightNeedARefresh = true
      }
    },
  },
  mounted() {
    bus.$on('refreshActionItems', () => {

      if (this.mightNeedARefresh) {
        this.refreshTheStuff()
      } else {
        bus.$emit('toast', { type: 'success', text: 'Action items up to date' })
      }
      
    })

    if (this.enrolledPatients.length > 0 && this.viableList.length === 0) {
      this.overallProgress = 0
      this.enrolledPatients.forEach((patient) => {
        this.gradePatientOnEngagement(patient)
      })
    }
  },
}
</script>


<style scoped>
.stickyHeader {
  position: sticky;
  top: 111px;
  z-index: 3;
  background-color: white;
  height: 60px;
}
.normalHeader {
  height: 60px;
}
.v-icon.active {
  transform: rotate(-180deg);
}
</style>
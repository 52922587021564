<template>
  <v-expand-transition>
      <v-row
        v-show="conditional"
        :class="{ stickyHeader: conditional }"
        no-gutters
      >
      <v-hover v-slot="{ hover }">
        <v-card
          style="cursor: pointer"
          flat
          height="60"
          class="flex"
          @click.native="$emit('toggleConditional')"
        >
          <v-row no-gutters align="center" class="fill-height" justify="start">
            <v-icon color="primary" v-if="icon" class="mr-1">{{icon}}</v-icon>
            <span v-if="permanentHighlight" class="font-weight-regular text-h6 primary--text"
              >{{title}}</span
            >

            <span v-else-if="hover || active" class="font-weight-light text-h6"
              >{{title}}</span
            >
            <span v-else class="font-weight-light text-h6 text--secondary"
              >{{title}}</span
            >
            <v-spacer />
            <v-fade-transition hide-on-leave>
        <v-card
          v-show="progress !== null && progress < 100"
          flat
          min-width="32"
          min-height="32"
          style="position: relative"
        >
          <v-row
            no-gutters
            style="position: absolute"
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-progress-circular
              size="24"
              width="3"
              color="primary"
              :value="progress"
            />
          </v-row>

          <v-row
            no-gutters
            style="position: absolute; opacity: 0.3"
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-progress-circular
              size="24"
              width="3"
              color="primary"
              indeterminate
            />
          </v-row>
        </v-card>
      </v-fade-transition>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-fab-transition>
            <v-btn
              v-show="
                progress >= 100 &&
                active && allowText
              "
              class="mr-2"
              depressed
              dark
              color="grey darken-1"
              v-bind="attrs"
              v-on="on"
              @click.stop.prevent="$emit('massText')"
              icon
            >
              <v-icon>mdi-comment-text-multiple</v-icon>
            </v-btn>
          </v-fab-transition>
        </template>
        Send Texts to Group
      </v-tooltip>


            <v-chip
              @click.stop.prevent="$emit('toggleConditional')"
              dark
              color="primary"
            >
              <span>{{total}}</span>

              <v-icon right :class="{ active: active }">
                mdi-chevron-down
              </v-icon>
            </v-chip>
          </v-row>
        </v-card>
      </v-hover>
      </v-row>
    </v-expand-transition>
</template>

<script>
export default {
data() {
    return {
      show: false,
      showExpansion: false,
      lastNote: null,
    };
  },
  props: {
    icon: null,
    permanentHighlight: {
      type: Boolean,
      default: false,
    },
    conditional: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    allowText: {
      type: Boolean,
      default: false,
    },
    title: null,
    total: null,
    progress: null
  },
}
</script>

<style scoped>
.stickyHeader {
  position: sticky;
  top: 111px;
  z-index: 3;
  background-color: white;
  height: 60px;
}
.normalHeader {
  height: 60px;
}
.v-icon.active {
  transform: rotate(-180deg);
}
</style>
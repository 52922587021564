var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expand-transition',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.conditional),expression:"conditional"}],class:{ stickyHeader: _vm.conditional },attrs:{"no-gutters":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"flex",staticStyle:{"cursor":"pointer"},attrs:{"flat":"","height":"60"},nativeOn:{"click":function($event){return _vm.$emit('toggleConditional')}}},[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":"","align":"center","justify":"start"}},[(_vm.icon)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.icon))]):_vm._e(),(_vm.permanentHighlight)?_c('span',{staticClass:"font-weight-regular text-h6 primary--text"},[_vm._v(_vm._s(_vm.title))]):(hover || _vm.active)?_c('span',{staticClass:"font-weight-light text-h6"},[_vm._v(_vm._s(_vm.title))]):_c('span',{staticClass:"font-weight-light text-h6 text--secondary"},[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),_c('v-fade-transition',{attrs:{"hide-on-leave":""}},[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.progress !== null && _vm.progress < 100),expression:"progress !== null && progress < 100"}],staticStyle:{"position":"relative"},attrs:{"flat":"","min-width":"32","min-height":"32"}},[_c('v-row',{staticClass:"fill-height",staticStyle:{"position":"absolute"},attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"size":"24","width":"3","color":"primary","value":_vm.progress}})],1),_c('v-row',{staticClass:"fill-height",staticStyle:{"position":"absolute","opacity":"0.3"},attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"size":"24","width":"3","color":"primary","indeterminate":""}})],1)],1)],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-fab-transition',[_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(
              _vm.progress >= 100 &&
              _vm.active && _vm.allowText
            ),expression:"\n              progress >= 100 &&\n              active && allowText\n            "}],staticClass:"mr-2",attrs:{"depressed":"","dark":"","color":"grey darken-1","icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('massText')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-comment-text-multiple")])],1)],1)]}}],null,true)},[_vm._v(" Send Texts to Group ")]),_c('v-chip',{attrs:{"dark":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('toggleConditional')}}},[_c('span',[_vm._v(_vm._s(_vm.total))]),_c('v-icon',{class:{ active: _vm.active },attrs:{"right":""}},[_vm._v(" mdi-chevron-down ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
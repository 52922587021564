<template>
  <v-card
    @click="cardClicked()"
    rounded="lg"
    flat
    :color="attentionColor"
  >
    <v-row no-gutters justify="start">
      <v-col cols="2" sm="4" md="2" >
  <v-row no-gutters>
          <v-card
            min-width="190"
            flat
            color="transparent"
            height="54"
          >
          
            <v-row
              class="fill-height ml-3"
              no-gutters
              justify="start"
              align="center"
            >
            <v-icon v-if="cardType === 'alert'" color="yellow darken-1"
            >mdi-alert-circle</v-icon
          >
          <v-icon v-else-if="cardType === 'Messages'" color="primary"
            >mdi-message-reply-text</v-icon
          >
          <v-tooltip v-else-if="item.tookReadingToday"  bottom>
        <template  v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs"
            v-on="on" color="green"
            >mdi-check-circle</v-icon
          >
        </template>
        {{item.firstName}} took a reading today
          </v-tooltip>
          <v-icon v-else color="primary">mdi-account-circle</v-icon>
              <v-col class="ml-3 block">
            <v-row class="text-truncate" no-gutters>
              <span class="font-weight-regular text-body-2"
                >{{ item.firstName }}
                <span class="font-weight-bold text-body-2">{{
                  item.lastName
                }}</span>
              </span>
            </v-row>
            <v-row no-gutters>
              <span class="text-caption">
                {{ item.age }} {{ item.shortGender }} ∙
                <span class="text--secondary" v-if="item.ownerPatientID">{{
                  item.ownerPatientID
                }}</span>
              </span>
            </v-row>
          </v-col>
            </v-row>
          </v-card>

  </v-row>
</v-col>

      <!-- this is the main body of the card in horizontal view-->
      <v-col cols="2" lg="1" >
        <v-row class="fill-height pr-2" align="center" justify="end" no-gutters>
          <v-card
            v-if="cardType === 'PatientFeed'"
     
            flat
            color="transparent"
            height="54"
          >
            <v-row
              class="fill-height"
              no-gutters
              justify="start"
              align="center"
            >
              <v-col class="ml-2">
                <v-row class="text-truncate" no-gutters>
                  <v-icon class="mr-1" size="12">mdi-calendar-check</v-icon>

                  <span
                    class="font-weight-regular text-caption text--secondary"
                  >
                    {{ 16 - item.daysNeeded }}/16 Days
                  </span>
                </v-row>
                <v-row no-gutters>
                  <v-progress-linear
                    class="my-2"
                    height="4"
                    rounded
                    :value="item.progress"
                  />
                </v-row>
              </v-col>
            </v-row>
          </v-card>

          <v-card
            v-if="cardType === 'closeOnTime'"
         
            flat
            color="transparent"
            height="54"
          >
            <v-row
              class="fill-height ml-3"
              no-gutters
              justify="start"
              align="center"
            >
              <v-col class="ml-2">
                <v-row class="text-truncate" no-gutters>
                  <v-icon class="mr-1" size="12">mdi-timer</v-icon>

                  <span
                    class="font-weight-regular text-caption text--secondary"
                  >
                    {{ item.millisecondsThisPeriodDisplay }}
                  </span>
                </v-row>
                <v-row no-gutters>
                  <v-progress-linear
                    class="my-2"
                    color="green"
                    height="4"
                    rounded
                    :value="(item.millisecondsThisPeriod / 1200000) * 100"
                  />
                </v-row>
              </v-col>
            </v-row>
          </v-card>

          <v-chip
            v-if="
              cardType === 'alert' && item.thresholdBreachAlertNotifications[0]
            "
            label
            class="px-2"
            color="yellow"
          >
            {{
              item.thresholdBreachAlertNotifications[0].event
                .dashboardAbbreviation
            }}
            {{ item.thresholdBreachAlertNotifications[0].event.eventString }}
          </v-chip>
        </v-row>
      </v-col>
      
      <v-col cols="3" lg="5" sm="4" md="4" class="d-none d-md-block">
        <v-row class="fill-height mx-2" align="center" no-gutters>
        
              <div class="text-body-2 d-inline-block text-truncate">
             
                <v-row class="text-truncate" no-gutters>
                  <span
                    v-if="cardType === 'alert' && item.thresholdBreachAlertNotifications[0]"
                    class="font-weight-regular text-caption text--secondary"
                    >{{
                      relativeTime(
                        item.thresholdBreachAlertNotifications[0].event
                          .timestamp
                      )
                    }}
                  </span>
                  <span
                    v-else-if="cardType === 'Messages'"
                    class="font-weight-regular text-caption text--secondary"
                    >{{ relativeTime(item.lastMessage.created) }}:
                  </span>
                  <span
                    v-else
                    class="font-weight-regular text-caption text--secondary"
                    >Detail
                  </span>
                </v-row>
                <v-row class="text-truncate" no-gutters>
                  <span
                    v-if="cardType === 'PatientFeed'"
                    class="text-body-2 d-inline-block text-truncate"
                  >
                    {{ item.actionNeeded }}
                  </span>
                  <span
                    v-if="cardType === 'noteMention'"
                    class="text-body-2 d-inline-block text-truncate"
                  >
                    {{ mentionText }}
                  </span>
                  <span
                    v-if="cardType === 'Messages'"
                    class="text-body-2 d-inline-block text-truncate"
                  >
                    {{ item.lastMessage.content }}
                  </span>
                  <span
                    v-if="cardType === 'alert' && item.thresholdBreachAlertNotifications[0]"
                    class="text-body-2 d-inline-block text-truncate"
                  >
                    {{ item.thresholdBreachAlertNotifications[0].note }}
                  </span>
                  <span
                    v-if="cardType === 'closeOnTime'"
                    class="text-body-2 d-inline-block text-truncate"
                  >
                    {{ item.qhpActionNeeded }}
                  </span>
                </v-row>
              
              </div>
            
        
        </v-row>
      </v-col>
<v-col cols="1" sm="2" md="1">
  <v-row no-gutters>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-card
            v-bind="attrs"
            v-on="on"
            flat
            min-width="120"
            color="transparent"
            height="54"
          >
            <v-row
              class="fill-height ml-3"
              no-gutters
              justify="start"
              align="center"
            >
              <v-col>
                <v-row class="text-truncate" no-gutters>
                  <v-icon class="mr-1" size="12">mdi-timer</v-icon>
                  <span class="font-weight-regular text-caption text--secondary"
                    >{{ getDateForHeader() }}
                  </span>
                </v-row>
                <v-row no-gutters>
                  <span class="text-body-2">
                    {{ item.millisecondsThisPeriodDisplay }}
                  </span>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </template>
        QHP time this month
      </v-tooltip>
  </v-row>
</v-col>
<v-col cols="1" sm="2" md="1">
  <v-row no-gutters>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-card
            v-bind="attrs"
            v-on="on"
            min-width="120"
            flat
            color="transparent"
            height="54"
          >
            <v-row
              class="fill-height ml-3"
              no-gutters
              justify="start"
              align="center"
            >
              <v-col>
                <v-row class="text-truncate" no-gutters>
                  <v-icon class="mr-1" size="12">mdi-poll</v-icon>
                  <span class="font-weight-regular text-caption text--secondary"
                    >Last Active
                  </span>
                </v-row>
                <v-row no-gutters>
                  <span class="text-body-2">
                    {{ relativeTime(item.rpmMostRecentData) }}
                  </span>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </template>
        Last reading: {{ getDateAndTimeFromTimestamp(item.rpmMostRecentData) }}
      </v-tooltip>

  </v-row>
</v-col>

      <v-col cols="2"  class="flex-shrink-1 flex-grow-0" no-gutters>
        <v-row no-gutters justify="end">
        <patient-summary-card-action-panel :item="item" />
      </v-row>
      </v-col>
    </v-row>
    <v-expand-transition>
      <div v-if="item.lastNote" v-show="showExpansion">
        <v-card-text>
          {{ relativeTime(item.lastNote.timestamp) }}
          {{ item.lastNote.author }} said:
          {{ item.lastNote.note }}
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import moment from 'moment-timezone'
import HelperMixin from '@/core/mixins/HelperMixin'
import { mapActions } from 'vuex'
import PatientSummaryCardActionPanel from './PatientSummaryCardActionPanel.vue'

export default {
  components: { PatientSummaryCardActionPanel },
  mixins: [HelperMixin],
  data() {
    return {
      show: false,
      showExpansion: false,
      lastNote: null,
    }
  },
  props: {
    item: null,
    mentionText: null,
    closeOnTime: {
      type: Boolean,
      default: false,
    },
    alertMode: {
      type: Boolean,
      default: false,
    },
    // the card type determines the basic layout, info displayed, and destination
    cardType: {
      type: String,
      default: 'PatientFeed',
    },
  },
  computed: {
    attentionColor() {
      if (this.item.tookReadingToday) {
        return 'grey lighten-4'
      }
      return 'grey lighten-4'
    },
    patientNavigationList() {
      let chartSections = []

      //   let patientChart = {icon: 'mdi-chart-areaspline', title: 'Patient Monitoring', tooltip: 'Go to Chart', destinationName: 'Patient'}
      //   chartSections.push(patientChart)

      // let chartShares = {icon: 'mdi-account-multiple', title: 'Care Team', tooltip: 'Care Team', destinationName: 'Followers'}
      // chartSections.push(chartShares)

      //   let exportFacesheet = {icon: 'mdi-cloud-print-outline', title: 'Export Facesheet', tooltip: 'Export Facesheet', actionName: 'exportFacesheet'}
      //   chartSections.push(exportFacesheet)

      let patientChartNotes = {
        badge: false,
        icon: 'mdi-file-document-box-plus-outline',
        title: 'Chart Notes',
        tooltip: 'Add a Note',
        destinationName: 'ChartNotes',
        color: 'grey darken-1',
      }
      if (this.item.lastNote) {
        if (this.item.noteToday) {
          patientChartNotes.badge = true
          patientChartNotes.color = 'primary'
        }

        patientChartNotes.extendedTooltip = true
        patientChartNotes.tooltipDetails =
          'Last note: ' +
          this.relativeTime(this.item.lastNote.timestamp) +
          ' ' +
          this.item.lastNote.author +
          ' wrote "' +
          this.item.lastNote.note +
          '"'
      }
      chartSections.push(patientChartNotes)

      let patientMessages = {
        badge: false,
        icon: 'mdi-message-text-outline',
        title: 'Messages',
        tooltip: 'Send a Message',
        destinationName: 'Messages',
        color: 'grey darken-1',
      }
      chartSections.push(patientMessages)

      let telehealthRoom = {
        badge: false,
        icon: 'mdi-message-video',
        title: 'Create Telehealth Room',
        tooltip: 'Open Telehealth Room',
        actionName: 'telehealth',
        color: 'grey darken-1',
      }
      chartSections.push(telehealthRoom)

      return chartSections
    },
  },
  methods: {
    ...mapActions('notes', ['getJustTheLastNote']),
    ...mapActions('taskboard', ['dismissUnreadMessageLocal']),
    relativeTime(value) {
      if (value) {
        let dt = moment(value)
          .tz(moment.tz.guess())
          .format('MM/DD/YYYY h:mm a')
        if (dt.includes('12:00 am')) {
          return 'Today'
        } else {
          return moment(value)
            .tz(moment.tz.guess())
            .fromNow()
        }
      }
      return '-'
    },
    relativeTime2(value) {
      let now = new Date().getTime()
      var Difference_In_Time = now - value

      let days = Difference_In_Time / (3600000 * 24)

      // if tis less than three days ago use moment.
      if (value && Math.floor(days) < 90) {
        let dt = moment(value)
          .tz(moment.tz.guess())
          .format('MM/DD/YYYY h:mm a')
        if (dt.includes('12:00 am')) {
          return 'Today'
        } else {
          return moment(value)
            .tz(moment.tz.guess())
            .calendar()
        }
      } else if (value) {
        return this.getConversationalDateAndTimeFromTimestamp(value)
      }
      return ''
    },
    cardClicked() {
      if (this.cardType === 'Messages') {
        if (this.item.unreadMessage) {
          this.dismissUnreadMessageLocal(this.item._id)
        }
        this.$router.push({
          name: 'Messages',
          params: { id: this.item._id },
        })
      } else if (this.cardType === 'alert') {
        // also trigger the alert?
        this.$router.push({
          name: 'PatientFeed/alert',
          params: { id: this.item._id },
        })
      } else if (this.cardType === 'closeOnTime') {
        // also trigger the alert?
        this.$router.push({
          name: 'PatientFeed',
          params: { id: this.item._id },
        })
      } else if (this.cardType === 'noteMention') {
        // also trigger the alert?
        this.$router.push({
          name: 'PatientFeed',
          params: { id: this.item._id },
        })
      } else {
        this.$router.push({
          name: this.cardType,
          params: { id: this.item._id },
        })
      }
    },
    navigateToPatientChart(destination) {
      if (destination.destinationName) {
        this.$router.push({
          name: destination.destinationName,
          params: { id: this.item._id },
        })
      }
    },
  },
  mounted() {
    this.show = true
  },
}
</script>

<style></style>
